<script>
export default {
    name: 'ViewBase',
    props: {
        touch: {
            type: Boolean,
            required: false,
            default () {
                return false
            }
        },
        sessionData: {
            required: false,
            default: () => null
        }
    },
    data () {
        return {
            saving: false,
            savingAml: false
        }
    },
    computed: {
        clientType () {
            return (this.sessionData) ? this.sessionData.clientType : null
        },
        datePickerLang () {
            return ((this.$store.state.lang === 'cz') ? 'cs' : 'en')
        },
        ivanHashId () {
            return this.sessionData?.ivanHashId ?? null
        }
    },
    watch: {
        touch (newValue) {
            if (newValue && this.$v !== undefined && this.$v.$touch !== undefined) {
                this.$v.$touch()
            }
        }
    },
    methods: {
        async saveSessionData (data) {
            this.saving = true
            try {
                const response = await this.$api.clientSession.update(data)
                this.$emit('update', response.data)
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('errors.sessionUpdateFailed'))
            } finally {
                this.$nextTick(() => {
                    this.saving = false
                })
            }
        },
        async saveAmlSessionData (data) {
            this.savingAml = true
            try {
                const response = await this.$api.amlSession.update(data)
                this.$emit('update', response.data)
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('errors.sessionUpdateFailed'))
            } finally {
                this.$nextTick(() => {
                    this.savingAml = false
                })
            }
        },
        getValidationState (vuelidateProp) {
            if (vuelidateProp === undefined) {
                return null
            }
            if (vuelidateProp.$dirty) {
                return !vuelidateProp.$invalid
            }
            return null
        },
        onPrevious () {
            this.$emit('previous')
        }
    }
}
</script>
